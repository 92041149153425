<template>
  <div class="rule_div">
    <el-form class="rule_top" inline>
      <el-form-item>
        <el-input class="rule_top-el" v-model="materialCode" style="width: 200px"
                  placeholder="备件编码"></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="queryLoading" @click="searching">查询</el-button>
        <el-button type="primary" @click="ruleDialog = true">添加检验规则</el-button>
      </el-form-item>
    </el-form>
    <el-table class="rule_table" :data="ruleList" border stripe>
      <el-table-column label="#" type="index" align="center"></el-table-column>
      <el-table-column label="备件编码" prop="materialCode" align="center"></el-table-column>
      <el-table-column label="创建时间" prop="createTime" align="center"></el-table-column>
      <el-table-column label="修改时间" prop="updateTime" align="center"></el-table-column>
      <el-table-column label="操作" align="center">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="change(scope.row.id)"><i class="el-icon-edit"></i> 编辑
          </el-button>
          <el-button type="danger" size="mini" @click="deleteRule(scope.row.id)">删除规则
          </el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="rule_page">
      <el-pagination @current-change="handleCurrentChange" :page-size="pageSize" background
                     layout="total, prev, pager, next"
                     :total="total"></el-pagination>
    </div>

    <!--添加检验比例对话框-->
    <el-dialog title="添加金钥匙检验比例" :visible.sync="ruleDialog" width="30%">
      <el-form :model="joinRule" label-width="150px">
        <el-form-item label="备件编码：">
          <el-input placeholder="请输入备件编码" v-model="joinRule.materialCode"></el-input>
        </el-form-item>
      </el-form>
      <span class="span_button">
        <el-button @click="ruleDialog = false">取 消</el-button>
        <el-button type="primary" :loading="addLoading" @click="addRule">确 定</el-button>
        </span>
    </el-dialog>

    <!--编辑规则对话框-->
    <el-dialog title="修改金钥匙检验比例" :visible.sync="changeRuleDialog" width="30%">
      <el-form :model="changeRule" label-width="150px">
        <el-form-item label="备件编码：">
          <el-input placeholder="请输入备件编码" v-model="changeRule.materialCode"></el-input>
        </el-form-item>
      </el-form>
      <span class="span_button">
        <el-button @click="changeRuleDialog = false">取 消</el-button>
        <el-button type="primary" :loading="updateLoading" @click="updateRule">确 定</el-button>
        </span>
    </el-dialog>

    <!--确认删除对话框-->
    <el-dialog title="提示" :visible.sync="deleteRuleDialog" width="30%">
      <span>确认删除金钥匙检验规则？</span>
      <span slot="footer" class="dialog-footer">
    <el-button @click="deleteRuleDialog = false">取 消</el-button>
    <el-button type="primary" :loading="deleteLoading" @click="affirm">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "GoldenKeyRate",
  data() {
    return {
      materialCode: '',
      ruleDialog: false,
      changeRuleDialog: false,
      deleteRuleDialog: false,
      deleteId: '',
      ruleList: [],
      joinRule: {
        materialCode: '',
      },
      changeRule: {
        materialCode: '',
      },
      pageNo: 1,
      total: 0,
      pageSize: 10,

      //“加载中”反馈
      addLoading: false,
      updateLoading: false,
      deleteLoading: false,
      queryLoading: false
    }
  },
  methods: {
    async addRule() {
      this.addLoading = true
      let {data: res} = await this.$axios.post('/busGoldenKeyRate/saveRate',
          this.joinRule);
      if (res.code === 0) {
        this.$message.success('添加成功');
        this.pageNo = 1
        await this.allRule()
        this.ruleDialog = false
      } else {
        this.$message.error(res.message);
      }
      this.addLoading = false
    },
    async change(id) {
      let {data: res} = await this.$axios.post('/busGoldenKeyRate/selectRate', {id: id});
      if (res.code === 0) {
        this.changeRule = res.data
        this.changeRuleDialog = true
      } else {
        this.$message.error(res.message);
      }
    },
    async updateRule() {
      this.updateLoading = true
      let {data: res} = await this.$axios.post('/busGoldenKeyRate/saveRate',
          this.changeRule);
      if (res.code === 0) {
        this.$message.success('修改成功');
        await this.allRule()
        this.changeRuleDialog = false
      } else {
        this.$message.error(res.message);
      }
      this.updateLoading = false
    },
    deleteRule(id) {
      this.deleteRuleDialog = true
      this.deleteId = id
    },
    async affirm() {
      this.deleteLoading = true
      let {data: res} = await this.$axios.post('/busGoldenKeyRate/deleteRate',
          {id: this.deleteId});
      if (res.code === 0) {
        this.$message.success("金钥匙检验规则删除成功")
        this.pageNo = 1
        await this.allRule()
      } else {
        this.$message.error(res.message)
      }
      this.deleteRuleDialog = false
      this.deleteLoading = false
    },
    async allRule() {
      let {data: res} = await this.$axios.post('/busGoldenKeyRate/selectList',
          {pageNo: this.pageNo});
      this.ruleList = res.data.records
      this.pageSize = res.data.size
      this.total = res.data.total
    },
    async handleCurrentChange(newPage) {
      this.pageNo = newPage
      let {data: res} = await this.$axios.post('/busGoldenKeyRate/selectList',
          {
            materialCode: this.materialCode,
            pageNo: this.pageNo
          }
      );
      this.ruleList = res.data.records
      this.pageSize = res.data.size;
      this.total = res.data.total
    },
    async searching() {
      this.queryLoading = true
      if (this.queryDate == null) {
        this.queryDate = ''
      }
      let {data: res} = await this.$axios.post('/busGoldenKeyRate/selectList',
          {
            materialCode: this.materialCode,
            pageNo: this.pageNo
          }
      );
      this.queryLoading = false
      if (res.code === 0) {
        this.ruleList = res.data.records
        this.pageSize = res.data.size;
        this.total = res.data.total
        if (res.data.total === 0) {
          this.$message.error('暂无符合条件的记录')
        } else {
          this.$message.success('共查询到' + res.data.total + '条记录')
        }
      } else {
        this.$message.error(res.message)
      }
    }
  },
  async created() {
    await this.allRule()
  }
}
</script>

<style scoped>

.span_button {
  margin-left: 30%;
}

.rule_top {
  margin: 20px 0 0 2%;
}

.rule_top-el {
  margin-right: 5px;
}

.rule_div {
  border: 1px solid #EBEEF5;
  border-radius: 5px;
}

.rule_table {
  text-align: center;
  margin-left: 2%;
  width: 95%;
  margin-bottom: 20px;
}

.rule_page {
  text-align: center;
  padding-bottom: 10px;
}
</style>
